<template>
  <div class="spinner"></div>
</template>

<script setup lang="ts">
// for tailwind intellisense colors are set with outer classes, e.g. "border-white/30 border-l-white"
// automatic thickness works good with em/rem units

const props = withDefaults(
  defineProps<{
    size?: string
    thickness?: string
  }>(),
  {
    size: "7rem",
  }
)

const thickness = computed(() => {
  if (props.thickness) {
    return props.thickness
  }

  const [value, units] = parseCSSUnits(props.size)

  return `${(value + 1) * 0.086}${units}`
})

function parseCSSUnits(value: string): [number, string] {
  const match = value.match(/(^\d*\.?\d*)([^\d]*)$/)
  if (!match) {
    return [0, ""]
  }

  return [+match[1], match[2]]
}
</script>

<style lang="scss" scoped>
:where(.spinner) {
  @apply relative mx-auto border-blue-700/40 border-l-blue-700;
}

.spinner {
  width: v-bind("props.size");
  height: v-bind("props.size");
  border-radius: 50%;
  border-width: v-bind("thickness");
  border-left-width: v-bind("thickness");
  transform: translateZ(0);
  animation: spin 1.1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
